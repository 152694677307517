import React, { useState, useEffect } from "react";
import "../../styles/Investers.css";
import PersonelDetails from "../../components/Admin/PersonalDetails";
import BankDetails from "../../components/Admin/BankDetails";
import FatchDeclaration from "../../components/Admin/FatchDeclaration";
import ManageNominess from "../../components/Admin/ManageNominess";
import { IoMdClose } from "react-icons/io";
import { FaCircleCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AddInvestor,
  UpdateInvestor,
  InvestorAddFormData,
  InvestorEditFormData,
  InvestorDetail,
} from "../../actions/adminAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sanitizeInput } from "../../utils/sanitizeInput";
import { toast } from "react-toastify";
import { INVESTOR_RESET } from "../../actions/types";
import Loader from "../../loader/Loader-ui";

const AddInvestorForm = () => {
  const dispatch = useDispatch(); // Initialize dispatch
  const navigate = useNavigate();
  const location = useLocation();
  const investorId = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // State to track the current step
  const totalSteps = 4; // Total number of steps
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isEditing = Boolean(investorId);
  const [noNominee, setNoNominee] = useState("N");
  const [nomineeCount, setNomineeCount] = useState(1);

  const fetchInvestorList = async () => {
    try {
      setIsLoading(true);
      await dispatch(InvestorEditFormData(investorId));
    } catch (error) {
      console.error("Error fetching investor:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch existing data if editing
  useEffect(() => {
    if (isEditing) {
      fetchInvestorList();
    } else {
      dispatch({ type: INVESTOR_RESET }); // Dispatch an action to reset FAQ detail data
    }
  }, [dispatch, investorId]);

  const investorData =
    useSelector((state) => state.admin.adminEditInvestorData?.data) || {};

  // Initial Values for Formik
  const initialValues = {
    // Personal Information
    userId: isEditing ? investorData?.profile?.user_id?.toString() || "" : "", // Only for edit
    name: investorData?.profile?.inv_name || "",
    pan: investorData?.profile?.pan || "",
    dob: investorData?.profile?.dob || "",
    adhaar_card: investorData?.profile?.adhaar_card || "",
    mobile: investorData?.profile?.mobile_no || "",
    email: investorData?.profile?.email || "",
    mobile_relation: investorData?.profile?.mobile_relation || "",
    email_relation: investorData?.profile?.email_relation || "",
    gender: investorData?.profile?.gender || "",
    marital_status: investorData?.profile?.marital_status || "",
    father_name: investorData?.profile?.father_name || "",
    occupation: investorData?.profile?.occupation || "",
    addr1: investorData?.profile?.addr1 || "",
    pincode: investorData?.profile?.pincode || "",
    city: investorData?.profile?.city || "",
    state: investorData?.profile?.state || "",
    nationality: investorData?.profile?.nationality || "INDIAN",

    // NRI Address (Nullable)
    nri_addr1: investorData?.nri_addr1 || '',
    nri_city: investorData?.nri_city || '',
    nri_pincode: investorData?.nri_pincode || '',
    nri_state: investorData?.nri_state || '',
    nri_country: investorData?.nri_country || '',

    // Bank Details
    bank_id: isEditing
      ? investorData?.user?.bank_accounts[0]?.bank_id || ""
      : "", // Only for edit
    acc_no: investorData?.user?.bank_accounts[0]?.acc_no?.toString() || "",
    acc_no_confirm:
      investorData?.user?.bank_accounts[0]?.acc_no?.toString() || "",
    bank_holder_name:
      investorData?.user?.bank_accounts[0]?.bank_holder_name || "", // Assuming account holder is investor
    acc_type: investorData?.user?.bank_accounts[0]?.acc_type || "",
    ifsc_code: investorData?.user?.bank_accounts[0]?.ifsc_code || "",
    bank_code: investorData?.user?.bank_accounts[0]?.bank_code || "",
    branch_name: investorData?.user?.bank_accounts[0]?.branch_name || "",
    branch_addr1: investorData?.user?.bank_accounts[0]?.branch_addr1 || "",
    file_attachment: investorData?.user?.cheque_image || "",
    set_default: investorData?.user?.bank_accounts[0]?.set_default || "N",

    // FATCA & Financial Info
    annual_income: investorData?.fatca?.annual_income || "",
    address_type: investorData?.fatca?.address_type || "",
    source_of_wealth: investorData?.fatca?.source_of_wealth || "",
    birth_place: investorData?.fatca?.birth_place || "",
    tax_residency: investorData?.fatca?.tax_residency || "N",
    // id_no: investorData?.fatca?.id_no || "",
    // idtype: investorData?.fatca?.idtype || "",

    // Nominee Details (Up to 3)
    nominee1_name: investorData?.profile?.nominee1_name || '',
    nominee1_relation: investorData?.profile?.nominee1_relation || '',
    nominee1_dob: investorData?.profile?.nominee1_dob || '',
    nominee1_percent: investorData?.profile?.nominee1_percent ? parseFloat(investorData?.profile?.nominee1_percent).toFixed(2) : 0 || "",
    nom1_pan: investorData?.profile?.nom1_pan || '',
    nominee1_guard_name: investorData?.profile?.nom1_guardian_relation || '',
    nominee1_guard_pan: investorData?.profile?.nominee1_guard_pan || '',
    nom1_guardian_relation:
      investorData?.profile?.nom1_guardian_relation || '',

    nominee2_name: investorData?.profile?.nominee2_name || '',
    nominee2_relation: investorData?.profile?.nominee2_relation || '',
    nominee2_dob: investorData?.profile?.nominee2_dob || '',
    nominee2_percent: investorData?.profile?.nominee2_percent? parseFloat(investorData?.profile?.nominee2_percent).toFixed(2) : 0 || "",
    nom2_pan: investorData?.profile?.nom2_pan || '',
    nominee2_guard_name: investorData?.profile?.nominee2_guard_name || '',
    nominee2_guard_pan: investorData?.profile?.nominee2_guard_pan || '',
    nom2_guardian_relation:
      investorData?.profile?.nom2_guardian_relation || '',

    nominee3_name: investorData?.profile?.nominee3_name || '',
    nominee3_relation: investorData?.profile?.nominee3_relation || '',
    nominee3_dob: investorData?.profile?.nominee3_dob || '',
    nominee3_percent: investorData?.profile?.nominee3_percent ? parseFloat(investorData?.profile?.nominee3_percent).toFixed(2) : 0 || "",
    nom3_pan: investorData?.profile?.nom3_pan || '',
    nominee3_guard_name: investorData?.profile?.nominee3_guard_name || '',
    nominee3_guard_pan: investorData?.profile?.nominee3_guard_pan || '',
    nom3_guardian_relation:
      investorData?.profile?.nom3_guardian_relation || '',
  };

  const sanitizeTest = (fieldName) => ({
    name: `is-sanitized-${fieldName}`,
    test: (value) => {
      const sanitizedValue = sanitizeInput(value);
      return sanitizedValue === value; // Ensure input matches sanitized output
    },
    message: `Input should be sanitized, and no action should occur`,
  });

  // Dynamic Validation Schema for Each Step
  const validationSchemas = [
    // Step 1: Personal Details
    Yup.object().shape({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Full Name must contain only alphabets")
        .required("Full Name is required"),
      dob: Yup.date().required("Date of Birth is required"),
      email: Yup.string()
        .email("Enter a valid email address")
        .required("Email is required"),
      email_relation: Yup.string()
        .notOneOf(["Select"], "Email Belongs To is required")
        .required("Email Belongs To is required"),
      mobile: Yup.string()
        .matches(/^\d{10}$/, "Must be 10 digits")
        .required("Mobile Number is required"),
      mobile_relation: Yup.string()
        .notOneOf(["Select"], "Mobile Belongs To is required")
        .required("Mobile Belongs To is required"),
      gender: Yup.string().required("Gender is required"),
      marital_status: Yup.string().required("Marital Status is required"),
      father_name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Parent Name must contain only alphabets")
        .required("Parent Name is required"),
      occupation: Yup.string()
        .notOneOf(["Select"], "Occupation is required")
        .required("Occupation is required"),
      pan: Yup.string()
        .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN")
        .required("PAN is required"),
      adhaar_card: Yup.string()
        .matches(/^\d{12}$/, "Must be 12 digits")
        .required("Aadhar is required"),
      addr1: Yup.string()
        .max(200, "Max 200 characters")
        .required("Address is required"),
      pincode: Yup.string()
        .matches(/^\d{6}$/, "Must be 6 digits")
        .required("Pincode is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string()
        .notOneOf(["Select"], "State is required")
        .required("State is required"),
    }),
    // Step 2: Bank Details
    Yup.object().shape({
      acc_no: Yup.string()
        .matches(/^\d+$/, "Account number must contain only numbers")
        .min(8, "Account number must be at least 8 digits")
        .max(16, "Account number must not exceed 16 digits")
        .required("Account number is required"),
      acc_no_confirm: Yup.string()
        .matches(/^\d+$/, "Account number must contain only numbers")
        .min(8, "Account number must be at least 8 digits")
        .max(16, "Account number must not exceed 16 digits")
        .required("Confirm Account Number is required")
        .oneOf([Yup.ref("acc_no")], "Account numbers do not match"),
      bank_holder_name: Yup.string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "Invalid Account Holder Name format")
        .required("Account Holder Name is required"),
      acc_type: Yup.string()
        .notOneOf(["Select"], "Account Type is required")
        .required("Account Type is required"),
      ifsc_code: Yup.string()
        .required("IFSC Code is required")
        .matches(
          /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/,
          "Invalid IFSC code, should follow format XXXX0XXXXXX"
        ),
      bank_code: Yup.string()
        .notOneOf(["Select"], "Bank Name is required")
        .required("Bank Name is required"),
      branch_name: Yup.string().required("Branch Name is required"),
      branch_addr1: Yup.string()
        .transform((value) => value.replace(/\s{2,}/g, " "))
        .test(sanitizeTest("Branch Address"))
        .max(119, "Input should not accept more than 119 characters")
        .required("Branch Address is required"),
      file_attachment: Yup.mixed()
        .required("Cancelled Cheque is required")
        .test("fileType", "Only PDF or image files are allowed", (value) => {
          if (!value) return false; // Ensure the value exists
          if (typeof value === "string") {
            // Handle file as a link
            const allowedExtensions = /\.(pdf|jpg|jpeg|png)$/i;
            return allowedExtensions.test(value);
          } else if (value instanceof File) {
            // Handle file as a File object
            const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
            return allowedTypes.includes(value.type);
          }
          return false;
        }),
      // file_attachment: Yup.mixed()
      // .required("Cancelled Cheque is required")
      // .test("fileType", "Only PDF or image files are allowed", (value) => {
      //   if (!value) return false; // Ensure the value exists
      //   if (typeof value === "string") {
      //     // Handle file as a link
      //     const allowedExtensions = /\.(pdf|jpg|jpeg|png)$/i;
      //     return allowedExtensions.test(value);
      //   } else if (value instanceof File) {
      //     // Handle file as a File object
      //     const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
      //     return allowedTypes.includes(value.type);
      //   }
      //   return false;
      // }),
      // file_attachment: Yup.mixed()
      // .required("Cancelled Cheque is required")
      // .test("fileType", "Only PDF or image files are allowed", (value) => {
      //   if (!value) return false;

      //   // Case 1: It's a string URL (from backend)
      //   if (typeof value === "string") {
      //     const allowedExtensions = /\.(pdf|jpg|jpeg|png)$/i;
      //     return allowedExtensions.test(value);
      //   }

      //   // Case 2: It's a File object
      //   if (value instanceof File) {
      //     const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
      //     return allowedTypes.includes(value.type);
      //   }

      //   // ✅ Case 3: It's a base64-wrapped object
      //   if (typeof value === "object" && value.type && value.data) {
      //     const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
      //     return allowedTypes.includes(value.type);
      //   }

      //   return false;
      // }),
    }),

    // Step 3: FATCA Declaration (Updated)
    Yup.object().shape({
      pan: Yup.string()
        .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN")
        .required("PAN is required"),
      annual_income: Yup.string()
        .notOneOf(["Select"], "Annual Income is required")
        .required("Annual Income is required"),
      address_type: Yup.string()
        .notOneOf(["Select"], "Address Type is required")
        .required("Address Type is required"),
      source_of_wealth: Yup.string()
        .notOneOf(["Select"], "Source of Income is required")
        .required("Source of Income is required"),
      birth_place: Yup.string()
        .test(sanitizeTest("Birth Place"))
        .max(100, "Input should not accept more than 100 characters")
        .required("Birth Place is required")
        .matches(/^[A-Za-z\s]+$/, "Only alphabets allowed"),
      // idtype: Yup.string()
      //   .notOneOf(["Select"], "ID Type is required")
      //   .required("ID Type is required"),
      // id_no: Yup.string().required("ID Number is required"),
      // tax_residency: Yup.string().required("Tax Residency is required"),
    }),
    // Step 4: Nominees (Updated Validation)
    noNominee === "Y"
    ? Yup.object({}) // Skip validation when no nominee selected
    : Yup.object().shape(
        [...Array(nomineeCount)].reduce((acc, _, index) => {
          const i = index + 1;
          return {
            ...acc,
            [`nominee${i}_name`]: Yup.string().required(
              "Nominee name is required"
            ),
            [`nominee${i}_relation`]: Yup.string().required(
              "Nominee relation is required"
            ),
            [`nominee${i}_dob`]: Yup.date().required("Date of birth is required"),
            [`nominee${i}_percent`]: Yup.number()
              .min(1, "Must be at least 1%")
              .max(100, "Cannot exceed 100%")
              .required("Nominee percentage is required"),
            [`nominee${i}_guard_name`]: Yup.string().when(
              `nominee${i}_dob`,
              (dob, schema) => {
                const age =
                  dob && !isNaN(new Date(dob).getTime())
                    ? new Date().getFullYear() - new Date(dob).getFullYear()
                    : null;
                return age !== null && age < 18
                  ? schema.required("Guardian name is required for minors")
                  : schema.notRequired();
              }
            ),
            [`nominee${i}_guard_pan`]: Yup.string().when(
              `nominee${i}_dob`,
              (dob, schema) => {
                const age =
                  dob && !isNaN(new Date(dob).getTime())
                    ? new Date().getFullYear() - new Date(dob).getFullYear()
                    : null;
                return age !== null && age < 18
                  ? schema.required("Guardian PAN is required for minors")
                  : schema.notRequired();
              }
            ),
            [`nom${i}_guardian_relation`]: Yup.string().when(
              `nominee${i}_dob`,
              (dob, schema) => {
                const age =
                  dob && !isNaN(new Date(dob).getTime())
                    ? new Date().getFullYear() - new Date(dob).getFullYear()
                    : null;
                return age !== null && age < 18
                  ? schema.required("Guardian relation is required for minors")
                  : schema.notRequired();
              }
            ),
          };
        }, {})
      ),
    // Yup.object().shape({
    //   ...[...Array(nomineeCount)].reduce((acc, _, index) => {
    //     const i = index + 1;
    //     return {
    //       ...acc,
    //       [`nominee${i}_name`]: Yup.string().required(
    //         "Nominee name is required"
    //       ),
    //       [`nominee${i}_relation`]: Yup.string().required(
    //         "Nominee relation is required"
    //       ),
    //       [`nominee${i}_dob`]: Yup.date().required("Date of birth is required"),
    //       [`nominee${i}_percent`]: Yup.number()
    //         .min(1, "Must be at least 1%")
    //         .max(100, "Cannot exceed 100%")
    //         .required("Nominee percentage is required"),
    //       // [`nom${i}_pan`]: Yup.string().when(
    //       //   `nominee${i}_dob`,
    //       //   (dob, schema) => {
    //       //     const age =
    //       //       dob && !isNaN(new Date(dob).getTime())
    //       //         ? new Date().getFullYear() - new Date(dob).getFullYear()
    //       //         : null;
    //       //     return age !== null && age >= 18
    //       //       ? schema.required("PAN is required for adults")
    //       //       : schema.notRequired();
    //       //   }
    //       // ),
    //       [`nominee${i}_guard_name`]: Yup.string().when(
    //         `nominee${i}_dob`,
    //         (dob, schema) => {
    //           const age =
    //             dob && !isNaN(new Date(dob).getTime())
    //               ? new Date().getFullYear() - new Date(dob).getFullYear()
    //               : null;
    //           return age !== null && age < 18
    //             ? schema.required("Guardian name is required for minors")
    //             : schema.notRequired();
    //         }
    //       ),
    //       [`nominee${i}_guard_pan`]: Yup.string().when(
    //         `nominee${i}_dob`,
    //         (dob, schema) => {
    //           const age =
    //             dob && !isNaN(new Date(dob).getTime())
    //               ? new Date().getFullYear() - new Date(dob).getFullYear()
    //               : null;
    //           return age !== null && age < 18
    //             ? schema.required("Guardian PAN is required for minors")
    //             : schema.notRequired();
    //         }
    //       ),
    //       [`nom${i}_guardian_relation`]: Yup.string().when(
    //         `nominee${i}_dob`,
    //         (dob, schema) => {
    //           const age =
    //             dob && !isNaN(new Date(dob).getTime())
    //               ? new Date().getFullYear() - new Date(dob).getFullYear()
    //               : null;
    //           return age !== null && age < 18
    //             ? schema.required("Guardian relation is required for minors")
    //             : schema.notRequired();
    //         }
    //       ),
    //     };
    //   }, {}),
    // }),
  ];  

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemas[currentStep - 1], // Adjust the index to match the step.
    onSubmit: async (values) => {
      // Loop through form values and append to FormData
      const formData = new FormData();

      for (const key in values) {
        if (key === "file_attachment") {
          if (values[key] instanceof File) {
            formData.append(key, values[key]);
          }
        } else {
          formData.append(key, values[key]);
        }
      }

      // ✅ Add noNominee manually if it's set to "Y"
      if (noNominee === "Y") {
        formData.append("noNominee", "Y");
      }

      // ✅ Nominee % validation (only when noNominee !== "Y")
      if (noNominee !== "Y") {
        let totalPercent = 0;

        for (let i = 1; i <= nomineeCount; i++) {
          const key = `nominee${i}_percent`;
          const val = values[key] ? parseFloat(values[key]) : 0;
          totalPercent += val;
        }

        if (totalPercent !== 100) {
          toast.error("Total nominee percentage must equal 100%");
          return; // 🛑 Prevent API call if validation fails
        }
      }
      let response;
      if (isEditing) {
        response = await dispatch(UpdateInvestor(formData));
      } else {
        response = await dispatch(AddInvestor(formData));
      }

      if (response?.status === false) {
        toast.error(response?.message || "Something went wrong!");
      } else {
        toast.success(response?.message || "Success!");
        navigate("/admin/investor"); // Redirect after success
      }
    },
    enableReinitialize: true,
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Modal open handler
  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleDelete = () => {
    closeModal();
  };

  // Function to handle the "Next" button
  // const handleNext = async () => {
  //   try {
  //     await formik.validateForm();
  
  //     // Step-specific fields to touch
  //     const stepFieldMap = {
  //       1: Object.keys(validationSchemas[0].fields), // Personal Details
  //       2: Object.keys(validationSchemas[1].fields), // Bank Details
  //       3: Object.keys(validationSchemas[2].fields), // FATCA
  //       4: Object.keys(validationSchemas[3].fields), // Nominee fields
  //     };
  
  //     const fieldsToTouch = stepFieldMap[currentStep] || [];
  //     console.log(fieldsToTouch,"check fieldsToTouch");
      
  //     // Prepare touched object
  //     const touchedObj = {};
  //     fieldsToTouch.forEach((field) => {
  //       touchedObj[field] = true;
  //     });
  //     console.log(touchedObj,"check touchedObj");
  //     console.log(formik.isValid,"check formik.isValid");

  //     formik.setTouched(touchedObj, true);
  
  //     // Let Formik rerender before checking isValid
  //     await new Promise((r) => setTimeout(r, 0));
  
  //     if (formik.isValid) {
  //       setCurrentStep((prevStep) => prevStep + 1);
  //     }
  //   } catch (error) {
  //     console.error("Validation failed:", error);
  //   }
  // };
  
  const handleNext = async () => {
    try {
      const stepFieldMap = {
        1: Object.keys(validationSchemas[0].fields),
        2: Object.keys(validationSchemas[1].fields),
        3: Object.keys(validationSchemas[2].fields),
        4: Object.keys(validationSchemas[3].fields),
      };
  
      const fieldsToTouch = stepFieldMap[currentStep] || [];
  
      // Mark all fields in the current step as touched
      const touchedObj = {};
      fieldsToTouch.forEach((field) => {
        touchedObj[field] = true;
      });
      await formik.setTouched(touchedObj, true);
  
      // Validate and get the error object directly
      const errors = await formik.validateForm();
  
      // Check if any of the current step fields have errors
      const hasErrorsInStep = fieldsToTouch.some((field) => errors[field]);
  
      if (!hasErrorsInStep) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };
  
  
  
  // Function to handle the "Back" button
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      setNomineeCount(1);
    }
  };

  // Function to get the step heading based on the current step
  const getStepHeading = (step) => {
    switch (step) {
      case 1:
        return (
          <div>
            <p className="step-heading">Step 1 of 4</p>
            <p className="step-label">Personal Details</p>
          </div>
        );
      case 2:
        return (
          <div>
            <p className="step-heading">Step 2 of 4</p>
            <p className="step-label">Bank Details</p>
          </div>
        );
      case 3:
        return (
          <div>
            <p className="step-heading">Step 3 of 4</p>
            <p className="step-label">FATCA Declaration</p>
          </div>
        );
      case 4:
        return (
          <div>
            <p className="step-heading">Step 4 of 4</p>
            <p className="step-label">Manage Nominees</p>
          </div>
        );
      default:
        return "";
    }
  };

  // Function to render the content for each step
  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <div>
            <PersonelDetails formik={formik} />
          </div>
        );
      case 2:
        return (
          <div>
            <BankDetails formik={formik} />
          </div>
        );
      case 3:
        return (
          <div>
            {/* FATCA Declaration Form */}
            <FatchDeclaration formik={formik} />
          </div>
        );
      case 4:
        return (
          <div>
            {/* Manage Nominees Form */}
            <ManageNominess
              formik={formik}
              nomineeCount={nomineeCount}
              setNomineeCount={setNomineeCount}
              noNominee={noNominee}
              setNoNominee={setNoNominee}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <form
      noValidate
      onSubmit={(e) => e.preventDefault()}
      className="hello-form"
    >
      <div className="container-fluid page_background">
        {isLoading && (
          <div className="loader-overlay">
            <Loader />
          </div>
        )}
        <div className="page-inner-content mb-3">
          <div className="page_inner_content_box">
            {/* Main Heading */}
            <h5 className="mb-4 main-heading-title">
              {isEditing ? "Edit Investor" : "Add Investor"}
            </h5>
            {/* Dynamic Step Heading */}
            <p
            // className="step-info"
            >
              {getStepHeading(currentStep)}
            </p>

            {/* Progress Bar */}
            <div className="mb-4">
              <div className="progress" style={{ height: "5px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${(currentStep / totalSteps) * 100}%` }}
                  aria-valuenow={(currentStep / totalSteps) * 100}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
            </div>

            {/* Card Content */}
            <div className="card redeam_bottom_card border-top-rounded">
              {renderStepContent(currentStep)}
            </div>

            {/* Navigation Buttons */}
            <div className="card-footer form_card form_card_border">
              <div className="d-flex justify-content-between gap-2">
                {currentStep > 1 && (
                  <button
                    type="button"
                    onClick={handleBack}
                    className="form_button"
                  >
                    Back
                  </button>
                )}
                {/* {currentStep === totalSteps ? (
                  <button
                    type="submit"
                    className="form_button next_btn"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default form submission
                      formik.handleSubmit();
                    }}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={handleNext}
                    // className="form_button next_btn"
                    className={`form_button next_btn ${
                      currentStep === 1 ? "next-btn-right" : ""
                    }`}
                  >
                    Next
                  </button>
                )} */}
                <button
                  type="button"
                  className={`form_button next_btn ${
                    currentStep === 1 ? "next-btn-right" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentStep === totalSteps) {
                      formik.handleSubmit(); // Submit the form on the final step
                    } else {
                      handleNext(); // Move to the next step
                    }
                  }}
                  disabled={formik.isSubmitting} // Only disable during submit
                >
                  {currentStep === totalSteps ? "Submit" : "Next"}
                </button>

                {/* <button
                  type="button"
                  className={`form_button next_btn ${
                    currentStep === 1 ? "next-btn-right" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentStep === totalSteps) {
                      formik.handleSubmit(); // Submit the form on the final step
                    } else {
                      handleNext(); // Move to the next step
                    }
                  }}
                  disabled={!formik.isValid || formik.isSubmitting} // Disable if the form is invalid or submitting
                >
                  {currentStep === totalSteps ? "Submit" : "Next"}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isModalOpen && (
                <div className="modal show">
                  <div className="invester-popup">
                    <div className="modal-header invester-modal-header">
                      <h5 className="modal-title"></h5>

                      <IoMdClose
                        onClick={closeModal}
                        className="close-popup-color"
                        style={{ margin: "10px" }}
                      />
                    </div>

                    <div className="modal-body invester-modal-body">
                      <FaCircleCheck
                        fontSize="large"
                        className="invester-modal-icon"
                      />

                      <p>
                        Investor details Added <br /> successfully!
                      </p>
                    </div>

                    <div className="invester-modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleDelete}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              )} */}
    </form>
  );
};

export default AddInvestorForm;
