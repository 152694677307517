import React, { useEffect, useState } from "react";
import "../../styles/mysip.css";
import { useDispatch, useSelector } from "react-redux";
import {
  nachSendOtp,
  nachVerifyOtp,
  purchaseCart,
} from "../../actions/cardAction";
import {
  GetInvestmentCartBuy,
} from "../../actions/adminAction";
import { getLocalStorageUserData } from "../../utils/userData";
import cancel from "../../images/popup/alert-image.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BuySipLumpsumModel = ({ isModalOpen, handleModalClose, amt, user_id, setOpenModel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = getLocalStorageUserData();
  const { adminInvestmentCartProductShow } = useSelector(
    (state) => state?.admin
  );
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [paymentMode, setPaymentMode] = useState("NACH");
  const [bankDetail, setBankDetail] = useState(adminInvestmentCartProductShow?.data?.enach[0]);
  const [openWitch, setOpenWhich] = useState();
  const [proceed, setProceed] = useState(false);
  
  useEffect(() => {
    setBankDetail(adminInvestmentCartProductShow?.data?.enach[0]);
  }, [adminInvestmentCartProductShow]);

  const handleModalCloseWithReset = (val) => {
    handleModalClose(); // Call the original modal close function
    setOpenModel(1);
    setIsOtpVerified(false);
    setPaymentMode("NACH");
    setProceed("false");
    setBankDetail("");
    setOpenWhich("");
    if (val != 1) {
      if (userData?.type === "R") {
        navigate("/rm/investors");
      } else {
        navigate("/admin/investor");
      }
    }
  };

  const callPurchaseApi = () => {
    if (amt <= 0) {
      toast.error("Amount must be geter then 0");
    } else {
      if (bankDetail != "") {
        const findBankId = adminInvestmentCartProductShow?.data?.bank_accounts?.find(
          (res) => res?.bank_code == bankDetail?.bank_code
        );

        const payload = {
          user_id: user_id,
          bank_account_id: findBankId?.id,
          no_netbanking: null,
          no_upi: null,
          mandate_id: bankDetail?.id,
          payment_mode: paymentMode,
          proceed: null,
          amt: amt,
        };
        dispatch(GetInvestmentCartBuy(payload))
          .then((res) => {
            setOpenWhich(res);
          })
          .catch((error) => {
            // handleModalCloseWithReset();
          });
      }
    }
  };

  useEffect(() => {
    if (openWitch) {
      if (openWitch?.data?.link && openWitch?.status == true) {
        window.location.href = openWitch?.data?.link;
      } else if (openWitch?.status == false) {
        toast.error(openWitch?.message, {
          autoClose: 2000, // Duration in milliseconds (5 seconds in this case)
        });
        // setTimeout(() => {
        //   window.location.href = "/sip-lumpsum-cart";
        // }, 1000);
      } else if (openWitch?.status == true) {
        toast.success(openWitch?.message);
        setTimeout(() => {
          if (userData?.type === "R") {
            navigate("/rm/orders", { state: { orderId: openWitch?.data?.order, userId: user_id } });
          } else {
            navigate("/admin/orders", { state: { orderId: openWitch?.data?.order, userId: user_id } });
          }
        }, 1000);
      }
    }
  }, [openWitch]);

  if (!isModalOpen) return null;

  return (
    <div className="container-fluid ">
      <div className=" mb-3">
        <div className="">
          <div className="modal_card">
            {/* Trigger Button */}
            {/* <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#paymentModal"
            >
              Select Payment modal
            </button> */}

            {/* Modal */}

            <div
              className={`modal fade modal_card_inner my-cart-custom-no-border ${
                isModalOpen ? "show" : ""
              }`}
              id="paymentModal"
              tabIndex="-1"
              aria-labelledby="paymentModalLabel"
              aria-hidden={!isModalOpen}
            >
              <div className="modal-dialog modal-dialog-centered p-3 modal-dialog-scrollable my-cart-scrollbar">
                <div className="modal-content cart-custom-popup">
                    <>
                      {adminInvestmentCartProductShow?.data?.enach?.length > 0 ? (
                        <div className="modal_body text-center">
                          <div className="mt-3 table-responsive">
                            <table
                              className="table sip_modaltable mb-0"
                              style={{
                                borderCollapse: "separate",
                                borderSpacing: "0px 15px",
                              }}
                            >
                              <tr>
                                <th></th>
                                <th className="fw-semibold">Bank</th>
                                <th className="fw-semibold">Account Number</th>
                                <th>Limit (Rs.)</th>
                              </tr>
                              <>
                                {adminInvestmentCartProductShow?.data?.enach?.length > 0 ? (
                                  adminInvestmentCartProductShow?.data?.enach?.map((res) => {
                                    return (
                                      <tr>
                                        <td>
                                          <input
                                            className="form-input sip_input"
                                            type="radio"
                                            name="bankId"
                                            id="bankId"
                                            onClick={() => {
                                              setBankDetail(res);
                                            }}
                                            checked={res?.id == bankDetail?.id}
                                          />
                                        </td>
                                        <td>
                                          <img
                                            src={res?.icon}
                                            className="bank_image"
                                          />
                                          {res?.bank_name}
                                        </td>
                                        <td>{res?.account_no}</td>
                                        <td>{res?.amount}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <p>No record found</p>
                                )}
                              </>
                            </table>
                            {bankDetail == "" && proceed && (
                              <p style={{ color: "red" }}>
                                Please, select bank
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          <>
                            <div
                              className={`modal-body text-center p-lg-5 p-4 `}
                            >
                              <img
                                src={cancel}
                                alt="Modal Icon"
                                className="mb-3"
                              />
                              <p style={{ color: "red", margin: "20px" }}>
                                You have not registered for eNACH, please
                                register.
                              </p>
                            </div>

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => handleModalCloseWithReset(1)} // Close modal on click
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className={`btn btn-success`}
                                onClick={handleModalCloseWithReset}
                              >
                                OK
                              </button>
                              <button
                                type="button"
                                className={`btn btn-primary`}
                                onClick={() => {
                                  handleModalCloseWithReset(1);
                                }}
                              >
                                Later
                              </button>
                            </div>
                          </>
                        </>
                      )}
                    </>
                  {adminInvestmentCartProductShow?.data?.enach?.length > 0 && (
                    <div className="modal-footer form_card ">
                      <div className="d-flex justify-content-between gap-3">
                        <button
                          type="submit"
                          className="form_button "
                          onClick={() => handleModalCloseWithReset(1)} // Close modal on click
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          disabled={
                            paymentMode == "NACH" && isOtpVerified
                              ? false
                              : bankDetail != ""
                              ? false
                              : true
                          }
                          onClick={() => {
                            setProceed(true);
                            callPurchaseApi();
                          }}
                          className="form_button next_btn"
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySipLumpsumModel;
