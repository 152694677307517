import React, { useEffect, useRef } from "react";
import SelectDropDown from "../SelectDropDown";
import { useDispatch, useSelector } from "react-redux";
import { InvestorAddFormData } from "../../actions/adminAction";
import { getBankDetail } from "../../actions/usersActions";
export default function StaticBankDetailsForm(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(InvestorAddFormData());
  }, [dispatch]);
  const userOnboardGetData =
    useSelector((state) => state.admin.adminAddInvestorData) || {};
  const { formik } = props;

  const accountTypeOptions = [
    { value: "SB", label: "Saving" },
    { value: "CA", label: "Current" },
    { value: "CC", label: "CashCredit" },
  ];
  const callDetilOnIFSCCode = (ifscCode) => {
    dispatch(getBankDetail(ifscCode))
      .then((res) => {
        const matchingOption = Object.entries(
          userOnboardGetData?.data?.banks
        ).find(([id]) => res.BANKCODE.includes(id));
        if (matchingOption) {
          const [value] = matchingOption;
          formik.setFieldValue("bank_code", value);
        } else {
          formik.setFieldValue("bank_code", res.BANKCODE);
        }

        formik.setFieldValue("branch_name", res?.BRANCH);
        formik.setFieldValue("branch_addr1", res?.ADDRESS);
      })
      .catch((error) => {
        console.log("Error:".error);
      });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  return (
    <div className="row form_body justify-content-center">
      <div className="col-xl-7 col-lg-10 col-md-10 my-2">
        <form>
          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="acc_no" className="form-label">
                Account Number <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
              <input
                type="text"
                className="form-control"
                id="acc_no"
                placeholder="Enter Account Number"
                value={formik.values.acc_no}
                {...formik.getFieldProps("acc_no")}
              />
              {formik.errors.acc_no ? (
                <div className="text-danger">{formik.errors.acc_no}</div>
              ) : null}
            </div>
          </div>
          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="acc_no_confirm" className="form-label">
                Confirm Account Number <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
              <input
                type="password"
                className="form-control"
                id="acc_no_confirm"
                placeholder="Confirm Account Number"
                {...formik.getFieldProps("acc_no_confirm")}
              />
              {formik.errors.acc_no_confirm ? (
                <div className="text-danger">
                  {formik.errors.acc_no_confirm}
                </div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="bank_holder_name" className="form-label">
                Account Holder Name <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
              <input
                type="text"
                className="form-control"
                id="accountHolderName"
                placeholder="Enter Account Holder Name"
                {...formik.getFieldProps("bank_holder_name")}
              />
              {formik.errors.bank_holder_name ? (
                <div className="text-danger">
                  {formik.errors.bank_holder_name}
                </div>
              ) : null}
            </div>
          </div>
          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="acc_type" className="form-label">
                Account Type <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
              <SelectDropDown
                name="acc_type"
                id=""
                placeholder="Select Account Type"
                value={formik.values.acc_type}
                changeFunction={(value) =>
                  formik?.setFieldValue("acc_type", value)
                }
                // options={userOnboardGetData?.data?.accountTypes?.map((bank) => ({
                //     value: bank.description,
                //     label: bank.description,
                //   }))}
                options={accountTypeOptions}
              />
              {formik.errors.acc_type ? (
                <div className="text-danger">{formik.errors.acc_type}</div>
              ) : null}{" "}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="ifsc_code" className="form-label">
                IFSC Code <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
              <input
                type="text"
                className="form-control"
                id="ifscCode"
                name="ifsc_code"
                placeholder="Enter IFSC Code"
                onChange={(e) => {
                  formik.handleChange(e); // Handle Formik's change
                  callDetilOnIFSCCode(e.target.value); // Call your custom function
                }}
                value={formik.values.ifsc_code}
              />
              {formik.errors.ifsc_code ? (
                <div className="text-danger">{formik.errors.ifsc_code}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="bank_code" className="form-label">
                Bank Name <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
              <SelectDropDown
                name="bank_code"
                id=""
                value={formik.values.bank_code}
                changeFunction={(value) =>
                  formik?.setFieldValue("bank_code", value)
                }
                placeholder="Select Bank Name"
                options={userOnboardGetData?.data?.banks?.map((bank) => ({
                  value: bank.bank_code,
                  label: bank.bank_name,
                }))}
              />
              {formik.errors.bank_code ? (
                <div className="text-danger">{formik.errors.bank_code}</div>
              ) : null}{" "}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="branch_name" className="form-label">
                Branch Name <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
              <input
                type="text"
                className="form-control"
                id="branch_name"
                placeholder="Enter Branch Name"
                {...formik.getFieldProps("branch_name")}
              />
              {formik.errors.branch_name ? (
                <div className="text-danger">{formik.errors.branch_name}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="branch_addr1" className="form-label">
                Branch Address <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
              <input
                type="text"
                className="form-control"
                id="branch_addr1"
                placeholder="Enter Branch Address"
                {...formik.getFieldProps("branch_addr1")}
              />
              {formik.errors.branch_addr1 ? (
                <div className="text-danger">{formik.errors.branch_addr1}</div>
              ) : null}
            </div>
          </div>
          <div className="row align-items-center mb-4">
            <div className="col-lg-5 col-md-6">
              <label htmlFor="cancelledCheque" className="form-label">
                Cancelled Cheque (Image/PDF){" "}
                <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6">
              {formik.values?.file_attachment ? (
                <>
                  <div>
                    {(() => {
                      const file = formik.values.file_attachment;

                      if (file instanceof File) {
                        const fileUrl = URL.createObjectURL(file); // NO ?t=... here
                        if (file.type === "application/pdf") {
                          return (
                            <embed
                              src={fileUrl}
                              type="application/pdf"
                              width="100%"
                              height="200px"
                              title="PDF Viewer"
                              style={{ border: "none" }}
                            />
                          );
                        } else {
                          return (
                            <img
                              src={fileUrl}
                              alt="Uploaded File"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          );
                        }
                      }

                      // Only append ?t=... for backend URLs (strings)
                      if (typeof file === "string") {
                        const fileUrl = file + `?t=${new Date().getTime()}`;
                        if (file.endsWith(".pdf")) {
                          return (
                            <embed
                              src={fileUrl}
                              type="application/pdf"
                              width="100%"
                              height="200px"
                              title="PDF Viewer"
                              style={{ border: "none" }}
                            />
                          );
                        } else {
                          return (
                            <img
                              src={fileUrl}
                              alt="Uploaded File"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          );
                        }
                      }

                      return null;
                    })()}
                  </div>

                  <input
                    type="file"
                    className="form-control mt-2"
                    accept="image/*,application/pdf"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      if (
                        file &&
                        ["image/jpeg", "image/png", "application/pdf"].includes(
                          file.type
                        )
                      ) {
                        formik.setFieldValue("file_attachment", file); // Just the File object
                      } else {
                        formik.setFieldError(
                          "file_attachment",
                          "Only PDF and image files are allowed"
                        );
                      }
                    }}
                  />

                  {formik.errors.file_attachment && (
                    <div className="text-danger">
                      {formik.errors.file_attachment}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*,application/pdf"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      if (
                        file &&
                        ["image/jpeg", "image/png", "application/pdf"].includes(
                          file.type
                        )
                      ) {
                        formik.setFieldValue("file_attachment", file);
                      } else {
                        formik.setFieldError(
                          "file_attachment",
                          "Only PDF and image files are allowed"
                        );
                      }
                    }}
                  />
                  {formik.errors.file_attachment && (
                    <div className="text-danger">
                      {formik.errors.file_attachment}
                    </div>
                  )}
                </>
              )}

              {/* {formik.values?.file_attachment ? (
                <>
                  <div>
                    {(() => {
                      const file = formik.values.file_attachment;

                      if (file && file.data && typeof file.data === "string") {
                        if (file.type === "application/pdf") {
                          return (
                            <embed
                              src={file.data}
                              type="application/pdf"
                              width="100%"
                              height="200px"
                              title="PDF Viewer"
                              style={{ border: "none" }}
                            />
                          );
                        } else {
                          return (
                            <img
                              src={file.data}
                              alt={file.name || "Uploaded File"}
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          );
                        }
                      }

                      if (typeof file === "string") {
                        if (file.endsWith(".pdf")) {
                          return (
                            <embed
                              src={file}
                              type="application/pdf"
                              width="100%"
                              height="200px"
                              title="PDF Viewer"
                              style={{ border: "none" }}
                            />
                          );
                        } else {
                          return (
                            <img
                              src={file}
                              alt="Uploaded File"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          );
                        }
                      }

                      return null;
                    })()}
                  </div>

                  <input
                    type="file"
                    className="form-control mt-2"
                    accept="image/*,application/pdf"
                    onChange={async (event) => {
                      const file = event.currentTarget.files[0];
                      console.log(file.type,"file");
                      
                      if (
                        file &&
                        ["image/jpeg", "image/png", "application/pdf"].includes(
                          file.type
                        )
                      ) {
                        const base64File = await toBase64(file);
                        formik.setFieldValue("file_attachment", {
                          name: file.name,
                          type: file.type,
                          data: base64File,
                        });
                      } else {
                        formik.setFieldError(
                          "file_attachment",
                          "Only PDF and image files are allowed"
                        );
                      }
                    }}
                  />

                  {formik.errors.file_attachment && (
                    <div className="text-danger">
                      {formik.errors.file_attachment}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*,application/pdf"
                    onChange={async (event) => {
                      const file = event.currentTarget.files[0];
                      if (
                        file &&
                        ["image/jpeg", "image/png", "application/pdf"].includes(
                          file.type
                        )
                      ) {
                        const base64File = await toBase64(file);
                        formik.setFieldValue("file_attachment", {
                          name: file.name,
                          type: file.type,
                          data: base64File,
                        });
                      } else {
                        formik.setFieldError(
                          "file_attachment",
                          "Only PDF and image files are allowed"
                        );
                      }
                    }}
                  />
                  {formik.errors.file_attachment && (
                    <div className="text-danger">
                      {formik.errors.file_attachment}
                    </div>
                  )}
                </>
              )} */}

              {/* {formik.values?.file_attachment ? (
                <>
                  <div>
                    {(() => {
                      const file = formik.values.file_attachment;
                      console.log(file,"filee");
                      
                      // Case 1: File object (uploaded freshly)
                      if (file instanceof File) {
                        const fileUrl = URL.createObjectURL(file);
                        if (file.type === "application/pdf") {
                          return (
                            <embed
                              src={fileUrl}
                              type="application/pdf"
                              width="100%"
                              height="200px"
                              title="PDF Viewer"
                              style={{ border: "none" }}
                            />
                          );
                        } else {
                          return (
                            <img
                              src={fileUrl}
                              alt="Uploaded File"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          );
                        }
                      }

                      // Case 2: String URL (from backend)
                      if (typeof file === "string") {
                        if (file.endsWith(".pdf")) {
                          return (
                            <embed
                              src={file}
                              type="application/pdf"
                              width="100%"
                              height="200px"
                              title="PDF Viewer"
                              style={{ border: "none" }}
                            />
                          );
                        } else {
                          return (
                            <img
                              src={file}
                              alt="Uploaded File"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          );
                        }
                      }

                      return null;
                    })()}
                  </div>

                  <input
                    type="file"
                    className="form-control mt-2"
                    accept="image/*,application/pdf"
                    onChange={async (event) => {
                      const file = event.currentTarget.files[0];
                      if (file && ["image/jpeg", "image/png", "application/pdf"].includes(file.type)) {
                        formik.setFieldValue("file_attachment", {file});
                      } else {
                        formik.setFieldError(
                          "file_attachment",
                          "Only PDF and image files are allowed"
                        );
                      }
                    }}
                  />

                  {formik.errors.file_attachment && (
                    <div className="text-danger">
                      {formik.errors.file_attachment}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*,application/pdf"
                    onChange={async (event) => {
                      const file = event.currentTarget.files[0];
                      if (file && ["image/jpeg", "image/png", "application/pdf"].includes(file.type)) {
                        const base64File = await toBase64(file); // Convert to base64
                        formik.setFieldValue("file_attachment", {
                          name: file.name,
                          type: file.type,
                          data: base64File,
                        });
                      } else {
                        formik.setFieldError(
                          "file_attachment",
                          "Only PDF and image files are allowed"
                        );
                      }
                    }}
                  />
                  {formik.errors.file_attachment && (
                    <div className="text-danger">
                      {formik.errors.file_attachment}
                    </div>
                  )}
                </>
              )} */}
              {/* {formik.values?.file_attachment ? (
                <>
                  <div>
                  {formik.values?.file_attachment?.endsWith(".pdf") ? (
                      <embed
                        src={formik.values?.file_attachment}
                        type="application/pdf"
                        width="100%"
                        height="200px"
                        title="PDF Viewer"
                        style={{ border: "none" }}
                      />
                    ) : (
                      <img
                        src={formik.values?.file_attachment}
                        alt="Uploaded File"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*,application/pdf"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      if (
                        file &&
                        ["image/jpeg", "application/pdf"].includes(file.type)
                      ) {
                        formik.setFieldValue("file_attachment", file);
                      } else {
                        formik.setFieldError(
                          "file_attachment",
                          "Only PDF and image files are allowed"
                        );
                      }
                    }}
                  />
                  {formik.errors.file_attachment ? (
                    <div className="text-danger">
                      {formik.errors.file_attachment}
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*,application/pdf"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      if (
                        file &&
                        ["image/jpeg", "application/pdf"].includes(file.type)
                      ) {
                        formik.setFieldValue("file_attachment", file);
                      } else {
                        formik.setFieldError(
                          "file_attachment",
                          "Only PDF and image files are allowed"
                        );
                      }
                    }}
                  />
                  {formik.errors.file_attachment ? (
                    <div className="text-danger">
                      {formik.errors.file_attachment}
                    </div>
                  ) : null}
                </>
              )} */}
            </div>
          </div>

          <div className="row align-items-center mb-4 ml-2">
            <div className="form-check form-check-inline ">
              <input
                className="form-check-input"
                style={{
                  marginLeft: "-10px",
                  border: "1px solid gray",
                }}
                type="checkbox"
                name="set_default"
                id="set_default"
                checked={formik.values.set_default === "Y"}
                onChange={(e) => {
                  formik.setFieldValue(
                    "set_default",
                    e.target.checked ? "Y" : "N"
                  );
                }}
                onBlur={formik.handleBlur}
              />
              <label className="form-check-label ms-2" htmlFor="male">
                Set as a primary account
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
