import trash from "../images/icons/delete_icon.svg";
import "../styles/sipCart.css";
import { Link } from "react-router-dom";
import SelectDropDown from "./SelectDropDown";
import { useDispatch, useSelector } from "react-redux";
import { Field, FieldArray } from "formik";
import { DeleteMyCart } from "../actions/exploreFundsActions";
import { getLocalStorageUserData } from "../utils/userData";
import PopupModel from "./PopupModel";
import successlogo from "../images/popup/success-popup.svg";
import cancel from "../images/popup/alert-image.svg";
import "../styles/profile.css";
import "../styles/popup-custom.css";
import { useState } from "react";
import defaultFundIcon from "../images/Default icon.svg";

const SipAndLumpsumCart = (props) => {
  const dispatch = useDispatch();
  const { addToCardData } = useSelector((state) => state.myCard);
  const userData = getLocalStorageUserData();
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const [cartId, setCartId] = useState("");
  const closeModal = () => {
    setIsPopupVisible(false);
  };

  const actionPopup = () => {
    const payload = {
      cart_product_id: cartId,
      id: userData.id,
    };
    dispatch(DeleteMyCart(payload));
  };

  return (
    <>
      {props?.values?.length > 0 ? (
        props?.values?.map((result, index) => {
          return (
            <>
              <div
                className="card Sip-card overflow-hiddden bg-white mt-3"
                key={index}
              >
                <div className="card-body p-0">
                  {" "}
                  <div className="fund-details-info d-flex flex-wrap align-items-center gap-3">
                    <img
                      src={result?.amc?.icon}
                      alt="Fund Logo"
                      className="fund-logo me-3 mb-sm-0 mb-2"
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite loop if default image fails
                        e.target.src = defaultFundIcon;
                      }}
                    />
                    <div>
                      {/* <Link to={`/explore/fund-detail`}> */}
                      <h6 className="fund-name">
                        {result?.cart_product?.product?.product_long_name}
                      </h6>
                      {/* </Link> */}
                      <div className="d-flex flex-wrap gap-2"></div>
                    </div>
                    <div className="ms-lg-auto mt-sm-0 mt-0 d-flex align-items-center">
                      <div className="right-toggle-section">
                        <div className="toggle-group">
                          <label className="toggle-option">
                            <input
                              type="radio"
                              value="sip"
                              checked={result?.invest_type === "sip"}
                              name={`products_in_cart[${index}].invest_type`}
                              disabled={
                                result?.sipProductlimit == null ? true : false
                              }
                              onChange={() => {
                                // props?.resetForm();

                                props.setFieldValue(
                                  `products_in_cart[${index}].amount`,
                                  addToCardData?.data?.products_in_cart?.[index]
                                    ?.sipProductlimit?.minimum_amount
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].stepup`,
                                  false
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].day`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].frequency`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].noofinstallment`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].step_amt`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].step_frequency`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].final_amt`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].invest_type`,
                                  "sip"
                                );
                              }}
                            />
                            SIP
                          </label>
                          <label className="toggle-option">
                            <input
                              type="radio"
                              value="lumpsum"
                              checked={result?.invest_type === "lumpsum"}
                              name={`products_in_cart[${index}].invest_type`}
                              onChange={() => {
                                // props?.resetForm();
                                props.setFieldValue(
                                  `products_in_cart[${index}].amount`,
                                  addToCardData?.data?.products_in_cart?.[index]
                                    ?.lumpsumProductlimit?.minimum_amount
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].stepup`,
                                  false
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].day`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].frequency`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].noofinstallment`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].step_amt`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].step_frequency`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].final_amt`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].invest_type`,
                                  "lumpsum"
                                );
                              }}
                            />
                            Lumpsum
                          </label>
                        </div>
                        {/* start setp up code comment beacuse of client side pending we need api from nsc and backend side pending that's why */}
                        {/* <div className="divider_toggle"></div>
                        <div className="step-up-toggle">
                          <label className="toggle-option"> Step Up? </label>
                          <div className="form-check form-switch ms-2">
                            <input
                              className="form-check-input shadow-none switch_mode"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              checked={result?.stepup ? true : false}
                              onChange={() => {
                                props?.setFieldValue(
                                  `products_in_cart[${index}].stepup`,
                                  !result?.stepup
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].step_amt`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].step_frequency`,
                                  ""
                                );
                                props?.setFieldValue(
                                  `products_in_cart[${index}].final_amt`,
                                  ""
                                );
                              }}
                              disabled={
                                result?.invest_type === "lumpsum" ? true : false
                              }
                            />
                          </div>
                        </div> */}
                        {/* end setp up code comment beacuse of client side pending we need api from nsc and backend side pending that's why */}
                      </div>
                    </div>
                  </div>
                  {/* Input Fields */}
                  <div className=" mt-3">
                    <div className="investment-inputs d-flex gap-3">
                      <div className="input_group">
                        <label for="folio" className="form-lable">
                          Folio No.
                        </label>
                        <SelectDropDown
                          id="folio"
                          name={`products_in_cart[${index}].folionumber`}
                          value={result?.folionumber}
                          changeFunction={(value) =>
                            props?.setFieldValue(
                              `products_in_cart[${index}].folionumber`,
                              value
                            )
                          }
                          options={Object.keys(result?.folio).map((key) => ({
                            value: key,
                            label: key,
                          }))}
                          // options={result?.folioList?.map((folio) => ({
                          //   value: folio,
                          //   label: folio,
                          // }))}
                        />
                        {props?.formik.errors?.products_in_cart?.[index]
                          ?.folionumber ? (
                          <div className="text-danger">
                            {
                              props?.formik.errors.products_in_cart?.[index]
                                ?.folionumber
                            }
                          </div>
                        ) : null}
                      </div>
                      <div className="input_group">
                        <label for="folio" className="form-lable">
                          Scheme <span className="text-danger">*</span>
                        </label>
                        <SelectDropDown
                          id="reinvest"
                          name={`products_in_cart[${index}].reinvest`}
                          value={result?.reinvest}
                          changeFunction={(value) => {
                            props?.setFieldValue(
                              `products_in_cart[${index}].reinvest`,
                              value
                            );
                          }}
                          options={
                            result?.cart_product?.product?.reinvest_tag == "Z"
                              ? [
                                  {
                                    value: "Z",
                                    label: "Growth",
                                  },
                                ]
                              : result?.sreinvest == "N"
                              ? [
                                  {
                                    value: "N",
                                    label: "Dividend Payout",
                                  },
                                ]
                              : result?.cart_product?.product?.reinvest_tag ==
                                "Y"
                              ? [
                                  {
                                    value: "Y",
                                    label: "Dividend Reinvest",
                                  },
                                ]
                              : result?.cart_product?.product?.reinvest_tag ==
                                "X"
                              ? [
                                  {
                                    value: "Y",
                                    label: "Dividend Reinvest",
                                  },
                                  {
                                    value: "N",
                                    label: "Dividend Payout",
                                  },
                                ]
                              : []
                          }
                          // options={result?.folioList?.map((folio) => ({
                          //   value: folio,
                          //   label: folio,
                          // }))}
                        />
                        {props?.formik.errors?.products_in_cart?.[index]
                          ?.reinvest ? (
                          <div className="text-danger">
                            {
                              props?.formik.errors.products_in_cart?.[index]
                                ?.reinvest
                            }
                          </div>
                        ) : null}
                      </div>
                      <div className="input_group">
                        <label className="form-lable">
                          Amount <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name={`products_in_cart[${index}].amount`}
                          value={result?.amount || ""}
                          onChange={props?.handleChange}
                        />
                        {props?.formik.errors?.products_in_cart?.[index]
                          ?.amount ? (
                          <div className="text-danger">
                            {
                              props?.formik.errors.products_in_cart?.[index]
                                ?.amount
                            }
                          </div>
                        ) : null}
                      </div>
                      <div className="input_group">
                        <label for="day" className="form-lable">
                          Day{" "}
                          {result?.invest_type === "sip" ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <SelectDropDown
                          name={`products_in_cart[${index}].day`}
                          id="day"
                          value={result?.day}
                          changeFunction={(value) =>
                            props?.setFieldValue(
                              `products_in_cart[${index}].day`,
                              value
                            )
                          }
                          options={
                            result?.sipDates &&
                            Object.keys(result?.sipDates).map((key) => ({
                              value: result?.sipDates[key],
                              label: result?.sipDates[key],
                            }))
                          }
                          disabled={
                            result?.invest_type == "lumpsum" ? true : false
                          }
                        />
                        {props?.formik.errors?.products_in_cart?.[index]
                          ?.day ? (
                          <div className="text-danger">
                            {
                              props?.formik.errors.products_in_cart?.[index]
                                ?.day
                            }
                          </div>
                        ) : null}
                      </div>
                      <div className="input_group">
                        <label className="form-lable">
                          Frequency{" "}
                          {result?.invest_type === "sip" ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <SelectDropDown
                          name={`products_in_cart[${index}].frequency`}
                          id="frequency"
                          value={result?.frequency}
                          changeFunction={(value) =>
                            props?.setFieldValue(
                              `products_in_cart[${index}].frequency`,
                              value
                            )
                          }
                          options={
                            result?.frequencies &&
                            Object.keys(result?.frequencies).map((key) => ({
                              value: key,
                              label: result?.frequencies[key],
                            }))
                          }
                          disabled={
                            result?.invest_type == "lumpsum" ? true : false
                          }
                        />
                        {props?.formik.errors?.products_in_cart?.[index]
                          ?.frequency ? (
                          <div className="text-danger">
                            {
                              props?.formik.errors.products_in_cart?.[index]
                                ?.frequency
                            }
                          </div>
                        ) : null}
                      </div>
                      <div className="input_group">
                        <label className="form-lable">
                          No. of Installments{" "}
                          {result?.invest_type === "sip" ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <SelectDropDown
                          name={`products_in_cart[${index}].noofinstallment`}
                          id="noofinstallment"
                          value={result?.noofinstallment}
                          changeFunction={(value) => {
                            props?.setFieldValue(
                              `products_in_cart[${index}].noofinstallment`,
                              value
                            );
                            props?.setFieldValue(
                              `products_in_cart[${index}].until_cancel`,
                              0
                            );
                          }}
                          options={
                            result?.sipTenures &&
                            Object.keys(result?.sipTenures).map((key) => ({
                              value: result?.sipTenures[key],
                              label: result?.sipTenures[key],
                            }))
                          }
                          disabled={
                            result?.invest_type == "lumpsum"
                              ? true
                              : result?.until_cancel == 1
                              ? true
                              : false
                          }
                        />
                        {props?.formik.errors?.products_in_cart?.[index]
                          ?.noofinstallment ? (
                          <div className="text-danger">
                            {
                              props?.formik.errors.products_in_cart?.[index]
                                ?.noofinstallment
                            }
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="form-check form-check-inline"
                        style={{ paddingTop: "30px" }}
                      >
                        {console.log(
                          "result?.noofinstallment",
                          result?.noofinstallment
                        )}
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="until_cancelled"
                          name={`products_in_cart[${index}].until_cancel`}
                          disabled={
                            result?.invest_type == "lumpsum"
                              ? true
                              : result?.noofinstallment == "" ||
                                result?.noofinstallment == "Select" ||
                                result?.noofinstallment == undefined
                              ? false
                              : true
                          }
                          // value={result?.until_cancel == 0 ? false : true || ""}
                          checked={result?.until_cancel == 1}
                          onChange={(e) => {
                            if (e.target.checked == true) {
                              props?.setFieldValue(
                                `products_in_cart[${index}].until_cancel`,
                                1
                              );
                              // formik.setFieldValue("until_cancelled", "Y");
                            } else {
                              props?.setFieldValue(
                                `products_in_cart[${index}].until_cancel`,
                                0
                              );
                              // formik.setFieldValue("until_cancelled", "N");
                            }
                          }}
                        />
                        <label className="form-check-label ms-2" htmlFor="male">
                          Until Cancelled
                        </label>
                        {/* {formik.touched.until_cancelled &&
                          formik.errors.until_cancelled && (
                            <small className="text-danger">
                              {formik.errors.until_cancelled}
                            </small>
                          )} */}
                      </div>
                      {/* start setp up code comment beacuse of client side pending we need api from nsc and backend side pending that's why */}
                      {/* <div className="input_group">
                        <label className="form-lable">Step-Up Amount</label>
                        <input
                          type="number"
                          name={`products_in_cart[${index}].step_amt`}
                          className="form-control"
                          placeholder="Enter"
                          value={result?.step_amt || ""}
                          disabled={
                            result?.invest_type == "lumpsum"
                              ? true
                              : result?.invest_type == "sip" &&
                                result?.stepup == true
                              ? false
                              : true
                          }
                          onChange={props?.handleChange}
                        />
                        {props?.formik.errors?.products_in_cart?.[index]
                          ?.step_amt ? (
                          <div className="text-danger">
                            {
                              props?.formik.errors.products_in_cart?.[index]
                                ?.step_amt
                            }
                          </div>
                        ) : null}
                      </div>
                      <div className="input_group">
                        <label className="form-lable">Step-Up Frequency</label>
                        <SelectDropDown
                          name={`products_in_cart[${index}].step_frequency`}
                          id="step_frequency"
                          value={result?.step_frequency}
                          changeFunction={(value) =>
                            props?.setFieldValue(
                              `products_in_cart[${index}].step_frequency`,
                              value
                            )
                          }
                          disabled={
                            result?.invest_type == "lumpsum"
                              ? true
                              : result?.invest_type == "sip" &&
                                result?.stepup == true
                              ? false
                              : true
                          }
                          options={[
                            {
                              label: "Monthly",
                              value: "M",
                            },
                            {
                              label: "Yearly",
                              value: "Y",
                            },
                          ]}
                        />
                        {props?.formik.errors?.products_in_cart?.[index]
                          ?.step_frequency ? (
                          <div className="text-danger">
                            {
                              props?.formik.errors.products_in_cart?.[index]
                                ?.step_frequency
                            }
                          </div>
                        ) : null}
                      </div>
                      <div className="input_group">
                        <label className="form-lable">Final Amount</label>
                        <input
                          type="number"
                          name={`products_in_cart[${index}].final_amt`}
                          className="form-control"
                          placeholder="Enter"
                          disabled={
                            result?.invest_type == "lumpsum"
                              ? true
                              : result?.invest_type == "sip" &&
                                result?.stepup == true
                              ? false
                              : true
                          }
                          value={result?.final_amt || ""}
                          onChange={props?.handleChange}
                        />
                        {props?.formik.errors?.products_in_cart?.[index]
                          ?.final_amt ? (
                          <div className="text-danger">
                            {
                              props?.formik.errors.products_in_cart?.[index]
                                ?.final_amt
                            }
                          </div>
                        ) : null}
                      </div> */}
                      {/* end setp up code comment beacuse of client side pending we need api from nsc and backend side pending that's why */}
                      <div
                        className="delete-action align-self-end ms-auto"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Delete"
                      >
                        <Link
                          to=""
                          onClick={() => {
                            setCartId(result?.cart_product?.id);
                            setIsPopupVisible(true);
                          }}
                          className="btn_delete ms-3"
                        >
                          <img src={trash} alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <p>My card not found</p>
      )}

      {isPopupVisible && (
        <PopupModel
          id="alertModel"
          title="Lorem Ipsum"
          content="Are you sure you want to delete this fund?"
          image={cancel}
          closeModal={closeModal}
          btnColor={"btn-danger"}
          actionPopup={actionPopup}
          customClass="cart-custom-popup" // Custom class for styling
          custombodyclass="custom-popup-content" // Custom class for styling
        />
      )}
    </>
  );
};

export default SipAndLumpsumCart;
